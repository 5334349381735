<template>
  <dx-popup
    :hide-on-outside-click="true"
    :drag-enabled="false"
    :height="popupHeight"
    max-height="80vh"
    min-width="300px"
    width="80vw"
    :show-title="true"
    title="Dados da situação da caçamba"
    :visible.sync="popupActive">
    <template v-slot:content>
      <dx-scroll-view
        v-if="solicitacaoData"
        direction="vertical"
        use-native>
        <div class="popup-avaliar-recebimento-content">
          <div><strong>Nº do CTR: </strong>{{ solicitacaoData.id }}</div>
          <div><strong>Data/Hora da Solicitação: </strong>{{ dataHoraSolicitacao }}</div>
          <div><strong>Solicitante: </strong>{{ nomeSolicitante }}</div>
          <div><strong>CPF/CNPJ: </strong>{{ cpfCnpjSolicitante }}</div>
          <div><strong>Endereço da Obra: </strong>{{ enderecoObra }}</div>
          <div></div>
          <div><strong>Transportador: </strong>{{ nomeTransportador }}</div>
          <div><strong>CPF/CNPJ: </strong>{{ cpfCnpjTransportador }}</div>
          <div><strong>Classe de material/Tipo de resíduo predominante: </strong>{{ residuoPredominante }}</div>
          <div></div>
          <div><strong>Veículo (Placa): </strong>{{ veiculoPlaca }}</div>
          <div><strong>Tipo: </strong>{{ veiculoTipo }}</div>
          <div><strong>Nº de Identificação da Caçamba: </strong>{{ identificacaoCacamba }}</div>
          <div><strong>Volume em m³: </strong>{{ volumeCacamba }}</div>
          <hr class="span-2 width-100"/>
          <form-rejeitar-recebimento
            v-if="acceptResiduo && acceptResiduo.value === false"
            class="span-2"
            v-model="solicitacaoData"/>
          <div></div>
          <div>
            <strong>Receber Resíduo</strong>
            <dx-radio-group
              v-bind="fieldAcceptResiduooptions"
              :value.sync="acceptResiduo"/>
          </div>
          <message-error
            v-if="popupErrorMessage"
            class="span-2"
            :message.sync="popupErrorMessage"/>
          <div class="span-2 popup-avaliar-recebimento-buttons">
            <dx-button
              v-if="acceptResiduo"
              type="success"
              @click="onButtonSaveClick">
              Salvar
            </dx-button>
            <dx-button type="normal" @click="popupActive = false">Voltar</dx-button>
          </div>
        </div>
      </dx-scroll-view>
      <message-error v-else-if="popupErrorMessage" :message.sync="popupErrorMessage"/>
    </template>
  </dx-popup>
</template>
<script>
import DxPopup from 'devextreme-vue/popup'
import DxScrollView from 'devextreme-vue/scroll-view'
import DxRadioGroup from 'devextreme-vue/radio-group'
import notify from 'devextreme/ui/notify'
import DxButton from 'devextreme-vue/button'
import _ from 'lodash'
import moment from 'moment'
import { mapActions } from 'vuex'

import {
  getSolicitacaoById,
  avaliarRecebimento
} from './service'
import MessageError from '../../../components/MessageError'
import FormRejeitarRecebimento from './FormRejeitarRecebimento'

export default {
  name: 'popup-avaliar-recebimento',
  components: {
    DxPopup,
    DxScrollView,
    DxRadioGroup,
    DxButton,
    MessageError,
    FormRejeitarRecebimento
  },
  props: {
    /**
     * Se o popup esta ativo.
     */
    active: {
      type: Boolean,
      required: true
    },

    /**
     * A solicitacao que sera mostrada.
     */
    solicitacao: {
      type: Object
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      solicitacaoData: null,
      acceptResiduo: null,
      fieldAcceptResiduooptions: {
        layout: 'horizontal',
        items: [
          { text: 'Rejeitar', value: false },
          { text: 'Receber', value: true }
        ]
      },
      popupErrorMessage: null
    }
  },
  computed: {
    /**
     * Valor computado de se o popup esta ativo.
     * @returns {boolean}
     */
    popupActive: {
      get () {
        return this.active
      },
      set (v) {
        this.$emit('update:active', v)
      }
    },

    /**
     * Valor computado da data e hora da solicitacao.
     */
    dataHoraSolicitacao () {
      return this.solicitacaoData
        ? moment(this.solicitacaoData.dataHoraSolicitacao).format('DD/MM/YYYY - HH:mm')
        : ''
    },

    /**
     * Valor computado do nome do solicitante da solicitacao.
     */
    nomeSolicitante () {
      return _.get(this.solicitacaoData, 'obra.gerador.pessoa.nomeRazaoSocial')
    },

    /**
     * Valor computado do cpfCnpj do solicitante da solicitacao.
     */
    cpfCnpjSolicitante () {
      return _.get(this.solicitacaoData, 'obra.gerador.pessoa.cpfCnpj')
    },

    /**
     * Valor computado do endereco da obra da solicitacao.
     */
    enderecoObra () {
      return this.solicitacaoData.obra
        ? `${this.solicitacaoData.obra.endereco} - ${this.solicitacaoData.obra.cep}`
        : null
    },

    /**
     * Valor computado do nome do transportador da solicitacao.
     */
    nomeTransportador () {
      return _.get(this.solicitacaoData, 'transportador.pessoa.nomeRazaoSocial')
    },

    /**
     * Valor computado do cpfCnpj do transportador da solicitacao.
     */
    cpfCnpjTransportador () {
      return _.get(this.solicitacaoData, 'transportador.pessoa.cpfCnpj')
    },

    /**
     * Valor computado do residuo predominante da solicitacao.
     */
    residuoPredominante () {
      return _.get(this.solicitacaoData, 'tipoResiduoPredominante.nome')
    },

    /**
     * Valor computado da placa do veiculo da solicitacao.
     */
    veiculoPlaca () {
      return _.get(this.solicitacaoData, 'veiculo.placa')
    },

    /**
     * Valor computado do tipo do veiculo da solicitacao.
     */
    veiculoTipo () {
      return _.get(this.solicitacaoData, 'veiculo.tipo')
    },

    /**
     * Valor computado da identificacao da cacamba da solicitacao.
     */
    identificacaoCacamba () {
      return _.get(this.solicitacaoData, 'cacamba.numeroIdentificacao') || _.get(this.solicitacaoData, 'veiculo.numeroIdentificacao')
    },

    /**
     * Valor computado do volume da cacamba da solicitacao.
     */
    volumeCacamba () {
      return _.get(this.solicitacaoData, 'cacamba.capacidadeM3')
    },

    /**
     * Valor computado da altura do popup da solicitacao.
     */
    popupHeight () {
      return !this.solicitacaoData && !this.popupErrorMessage
        ? '100px'
        : this.popupErrorMessage ||
          !this.acceptResiduo ||
          this.acceptResiduo.value
          ? 'auto'
          : '80vh'
    }
  },
  watch: {
    /**
     * Metodo de callback de mudancas no valor da prop 'active', com o objetivo
     * de buscar os dados da solicitacao.
     * @param {boolean} active - O valor atual da prop.
     */
    async active (active) {
      if (active) {
        this.setLoading(true)
        this.popupErrorMessage = null
        this.solicitacaoData = null
        this.acceptResiduo = null

        if (this.solicitacao) {
          const solicitacaoResult = await getSolicitacaoById(this.solicitacao.id)

          if (solicitacaoResult.success) {
            this.solicitacaoData = solicitacaoResult.data
          } else {
            this.popupErrorMessage = solicitacaoResult.error
          }
        }
        this.setLoading(false)
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando foi clicado no botao de salvar do popup. Com
     * o objetivo de enviar a requisicao de salvar a avaliacao da solicitacao.
     */
    async onButtonSaveClick () {
      this.popupErrorMessage = null
      this.setLoading(true)

      if (!this.acceptResiduo) {
        this.popupErrorMessage = 'É preciso escolher se o recebimento do resíduo vai ser aceito ou não.'
        this.setLoading(false)
        return
      }

      if (!this.acceptResiduo.value &&
        (!this.solicitacaoData.observacao || !this.solicitacaoData.observacao.length)) {
        this.popupErrorMessage = 'A observação informada é inválida.'
        this.setLoading(false)
        return
      }

      const res = await avaliarRecebimento({
        avaliacao: this.acceptResiduo.value,
        solicitacao: this.solicitacaoData
      })

      if (res.success) {
        if (res.data.message) {
          notify(res.data.message, 'warning', 4000)
        }

        this.popupActive = false
        this.$emit('updated-solicitacao')
      } else {
        this.popupErrorMessage = res.error
      }

      this.setLoading(false)
    },
    ...mapActions('Crud', ['setLoading'])
  }
}
</script>

<style lang="scss">
@import "../../../global.scss";

.popup-avaliar-recebimento-content {
  display: grid;
  grid-template: repeat(2, 1fr) auto / 1fr 1fr;
  gap: 6px;

  .span-2 {
    grid-column: span 2;
  }

  > .popup-avaliar-recebimento-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 800px) {
  .popup-avaliar-recebimento-content {
    display: flex;
    flex-direction: column;

    > div {
      margin-bottom: 6px;
    }
  }
}
</style>
