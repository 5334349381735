import EventEmitter from 'events'
import notify from 'devextreme/ui/notify'
import { confirm } from 'devextreme/ui/dialog'
import lodash from 'lodash'
import { mapActions } from 'vuex'
import { parseFilterParams } from '@/util/dxDatasourceHelper'

/**
 * Tenta encontrar um padrão(por regex) do erro notificado pelo sequelize, para que seja exibido uma mensagem amigável ao usuário
 * informando algo mais detalhado sobre o erro ocorrido
 * @param {String} message
 * @return {null}
 */
export const treatSequelizeError = (message) => {
  let messageToReturn = null
  const unique = (message) => {
    const pattern = /^(.+)\smust\sbe\sunique/i
    const field = message.match(pattern)
    if (field && field[0]) {
      messageToReturn = `O valor do campo: ${field[1]} precisa ser único. Já existe um registro com este dado`
    }
  }
  unique(message)
  return messageToReturn
}

export const staticMethodsUtil = {
  snakeToCamel: str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    ),
  getDistinctSituacoes () {
    return ['Solicitada', 'Enviada', 'Alocada', 'Retirada', 'Destinada', 'Recusada', 'Cancelamento Solicitado', 'Cancelada', 'Cancelamento Recusado', 'Resíduo Rejeitado', 'Em Pesagem']
  },

  validateCpf: (cpf) => {
    cpf = cpf.replace(/\D/g, '')
    if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false
    }
    let result = true
    ;[9, 10].forEach((j) => {
      let soma = 0
      cpf.split(/(?=)/).splice(0, j).forEach((e, i) => {
        soma += parseInt(e) * ((j + 2) - (i + 1))
      })
      let r = soma % 11
      r = (r < 2) ? 0 : 11 - r
      if (String(r) !== cpf.substring(j, j + 1)) {
        result = false
      }
    })
    return result
  },

  validateCnpj: (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '')

    if (!cnpj) {
      return false
    }

    if (cnpj.length !== 14) { return false }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999') {
      return false
    }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho)
    const digitos = cnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) { pos = 9 }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (String(resultado) !== digitos.charAt(0)) {
      return false
    }

    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) { pos = 9 }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    return String(resultado) === digitos.charAt(1)
  },

  /**
   *
   * @param cep
   * @returns {string}
   */
  formatarCep: (cep) => {
    return String(cep)
      .replace(/[^\d]+/g, '')
      .replace(/^([\d]{2})([\d]{3})([\d]{3})$/, '$1.$2-$3')
  },

  /**
 *
 * @param cpf
 * @returns {string}
 */
  formatarCpf: (cpf) => {
  /* fixme:
         O ideal é que seja usada essa regex: /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/
         pois ela irá regrar que só será feito a máscara se houver 11 caracteres,
         porém a regex q implementei abaixo deixa passar essa obrigatoriedade fazendo com a máscara seja aplicada mesmo sem houver 11 digitos
     */
    return String(cpf)
      .replace(/[^\d]+/g, '')
      .replace(/^([\d]{3})?([\d]{3})?([\d]{3})?([\d]{2})?$/, '$1.$2.$3-$4')
  },

  /**
   * Método garante padronização e retorno estabelecido pela API do DevExtreme para o componente de Grid.
   * Deste método sempre será retornado um objeto(seguindo a interface da API) mesmo se um request REST der erro,
   * essa abordagem é para corrigir o problema de loop infinito que acontece no Grid do DevExtreme quando o serviço REST
   * responde um erro de consumo
   * @param {Promise} promiseRequest
   * @returns {Promise<{data: *[], totalCount: number}>}
   */
  async wrapRequestForGrid (promiseRequest) {
    let result = { data: [], totalCount: 0 }
    try {
      let resultRequest = await promiseRequest

      if (resultRequest && typeof resultRequest === 'object' && !(resultRequest instanceof Array)) {
        result = resultRequest
      } else {
        resultRequest = resultRequest || {}
        result.data = resultRequest instanceof Array ? resultRequest : (resultRequest.data || [])
        result.totalCount = +(resultRequest.totalCount || 0)
      }
    } catch (error) {
      // this.notifyError(error)
      console.error(error)
    }
    return result
  },

  /**
 *
 * @param cpf
 * @returns {string}
 */
  formatarCnpj: (cpf) => {
    return String(cpf)
      .replace(/[^\d]+/g, '')
      .replace(/^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/, '$1.$2.$3/$4-$5')
  },

  /**
 * Exibe mensagem com tela de confirmação
 * @param {String} message
 * @returns {Promise<boolean>}
 */
  showConfirm: async (message) => {
    return confirm(message)
  },

  /**
 * Notifica na tela uma mensagem de erro a partir de uma instancia de Error ou CustomError
 * @param {Error|CustomError} error
 * @param {String} [defaultMessage] Parâmetro obrigatório de um mensage de erro, será usada caso acontença uma exceção programática não tratada
 * @param {Number} [displayTime] Tempo de timeout da mensagem na tela
 */
  notifyError: (error, defaultMessage = 'Houve um problema durante este processamento', displayTime = 3000) => {
    let message = defaultMessage
    const httpResponseMessage = lodash.get(error, 'response.data.message', null)
    let httpAppTypeError = lodash.get(error, 'response.data.type', null)
    const serverBootErrorType = lodash.get(error, 'response.data.details.errorType', null)
    let emitAtConsole = true

    if (!httpAppTypeError && serverBootErrorType) {
      httpAppTypeError = serverBootErrorType
    }

    if (error instanceof CustomError) {
      message = error.message
    } else if (httpResponseMessage) {
      message = 'Não foi possível concluir esta requisição no momento.'

      const setOfTypeErrors = {
        CustomError: () => {
          message = httpResponseMessage
        },
        AuthException: () => {
          message = httpResponseMessage
        },
        SequelizeError: () => {
          const sequelizeMessage = treatSequelizeError(httpResponseMessage)
          if (sequelizeMessage) {
            message = sequelizeMessage
          }
          displayTime = 7000
        },
        SequelizeForeignKeyConstraintError: () => {
          message = 'Não foi possível removê-lo, pois existem outros registros vinculados'
          if (httpResponseMessage) {
            const regex = /fk_.+on.+table.+("[^"]+")/i
            const tableRelacionada = (httpResponseMessage.match(regex) || [])[1]
            if (tableRelacionada) {
              message += `. Fonte relacionada: ${tableRelacionada}`
            }
          }
          displayTime = 7000
        }
      }

      if (setOfTypeErrors[httpAppTypeError]) {
        setOfTypeErrors[httpAppTypeError]()
      }
      message = message + ' ' + error.response.data.message
      console.error('> Erro vindo do backend: ', error.response.data, error)
      emitAtConsole = false
    }
    notify(message, 'error', displayTime)
    if (emitAtConsole) {
      console.error(error)
    }
  },

  /**
 * Método responsável por manipular e fazer o parse dos valores de filtros de campos de tabelas relacionadas com o intuito de colocar o valor do where
 * dentro do include
 * @param {Array} loadOptionsFilter array contendo os valores de filtro , ex:  ['_usuario', 'nome', 'contains', 'VALOR DE FILTRO']
 * @param {filterName} filterName nome do filtro com "_", ex: '_usuario', este valor deve concidir com o valor passado na função "calculateFilterExpression" do devextreme
 */
  parseFilterValues: (loadOptionsFilter = [], filterName) => {
    let parsedFilter = {}
    for (const [index, filter] of Object.entries(loadOptionsFilter || [])) {
      if (filter[0] === filterName) {
        const { filterParams } = parseFilterParams([filter[1], filter[2], filter[3]])
        parsedFilter = filterParams
        loadOptionsFilter.splice(index, 1)
      }
    }
    return parsedFilter
  },

  /**
 * Notifica na tela uma mensagem de sucesso
 * @param {String} messageSuccess
 * @param {Number} displayTime Tempo de timeout da mensagem na tela
 */
  notifySuccess: (messageSuccess, displayTime = 3000) => {
    notify(messageSuccess, 'success', displayTime)
    console.info(messageSuccess)
  },

  parseToFormData: (payload = {}) => {
    const formData = new FormData()
    for (const key in payload) {
      let value = typeof payload[key] === 'number' ? String(payload[key]) : payload[key]
      if (value instanceof File) {
        formData.append(key, value)
        continue
      } else if (value && typeof value === 'object') {
        value = JSON.stringify(value)
      }
      if (typeof value !== 'undefined') {
        formData.append(key, value)
      }
    }
    return formData
  },

  /**
 * Verifica se o ID parametrizado é um ID numérico, o que sugere ser um ID de database. IDs temporários(UUID) serão retornados como false
 * @param {String|Number} id
 * @return {boolean}
 */
  isFinalId: (id) => {
    return id && /^\d+$/.test(id)
  },

  windowOpenToDownload: (href, fileName) => {
    const link = document.createElement('a')
    link.setAttribute('href', href)
    link.setAttribute('download', fileName)
    link.download = fileName
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

/**
 * Classe que possue os métodos utilitários reutilizáveis no plugin $utils, podendo ser usado nas instancias do vue
 * @class
 */
class UtilPluggable extends EventEmitter {
  constructor (vueInstance) {
    super()
    this.$ = vueInstance
    for (const key in staticMethodsUtil) {
      this[key] = staticMethodsUtil[key]
    }
  }

  async getEnderecamentoPeloCep (cep = '') {
    try {
      const enderecamento = lodash.get(await this.$.findAddress({
        cep,
        updateState: false
      }), 'info.endereco', {})

      if (!enderecamento || enderecamento.erro) {
        throw new CustomError('CEP não encontrado', 'error', 600)
      }
      return {
        bairro: enderecamento.bairro,
        uf: enderecamento.uf,
        localidade: enderecamento.localidade,
        complemento: enderecamento.complemento ? enderecamento.complemento : undefined,
        endereco: enderecamento.logradouro,
        codIbge: enderecamento.ibge
      }
    } catch (error) {
      let message = 'Erro na busca do CEP'
      if (error instanceof CustomError) {
        message = error.message
      }
      notify(message, 'error', 600)
      throw error
    }
  }
}

export default {
  install (Vue) {
    Vue.mixin({
      beforeCreate: function () {
        this.$utils = new UtilPluggable(this)
      },
      methods: {
        ...mapActions('Crud', ['findAddress'])
      }
    })
  }
}
