<template>
  <div class="avaliar-recebimento-solicitacao">
    <div>
      <popup-avaliar-recebimento
        :active.sync="popupAvaliacaoVisible"
        :solicitacao="solicitacaoPopup"
        @updated-solicitacao="onPopupUpdatedSolicitacao"/>

      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Consulta de recebimento de resíduo</h2>
        </div>
      </div>
      <simple-grid
        entity="SolicitacaoTransporte"
        :editable="false"
        :deletable="false"
        :grid-data="gridData"
        ref="simpleGrid"
        @content-ready="onGridContentReady"
      />
    </div>
    <DxDropDownBox style="display: none; width: 250px" id="dropdown-situacao"
      :show-clear-button="true"
      placeholder=""
    >
      <template>
        <DxList
          :data-source="this.$utils.getDistinctSituacoes().sort()"
          height="100%"
        >
          <template #item="{ data: situacao }">
            <div style="display: flex; height: 30px; align-items: center">
              <DxCheckBox :onValueChanged="(callback) => onMarkSituacaoFilter({callback, situacao})"
                          :value="!!customFiltersGrid.situacao.or.map(item => item.eq).includes(situacao)"/>
              <p style="padding-left: 10px">{{situacao}}</p>
            </div>
          </template>
        </DxList>
      </template>
    </DxDropDownBox>
  </div>
</template>
<script>
import config from 'config'

import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'

import {
  getSolicitacoesAvaliarRecebimento,
  getTransportadoresSolicitacoesReceber,
  getCacambasSolicitacao,
  getVeiculosSolicitacao,
  getResiduosPredominantesSolicitacao
} from './service'
import mixinCrud from '../mixin-crud'
import PopupAvaliarRecebimento from './PopupAvaliarRecebimento'
import { hasPermission } from '../../../util/permissions'
import { DxDropDownBox } from 'devextreme-vue/drop-down-box'
import DxList from 'devextreme-vue/list'
import DxCheckBox from 'devextreme-vue/check-box'

export default {
  name: 'list-avaliar-recebimento-solicitacao',
  components: {
    SimpleGrid,
    PopupAvaliarRecebimento,
    DxDropDownBox,
    DxList,
    DxCheckBox
  },
  mixins: [mixinCrud],
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      customFiltersGrid: {
        situacao: {
          or: [
            {
              eq: 'Retirada'

            },
            {
              eq: 'Cancelamento Recusado'
            },
            {
              eq: 'Em Pesagem'
            }
          ]
        }
      },
      gridData: {
        value: {
          visible: true
        },
        // filterValue: [['situacao', '=', 'Retirada'], 'or', ['situacao', '=', 'Em Pesagem']],
        columns: this.parseColumns([
          {
            dataField: 'id',
            caption: 'Nº do CTR',
            editorType: 'dxNumberBox'
          },
          {
            dataField: '$cacamba.id$',
            calculateDisplayValue: (data) => {
              return (data.cacamba && data.cacamba.numeroIdentificacao) ? data.cacamba.numeroIdentificacao : data.veiculo ? data.veiculo.numeroIdentificacao : ''
            },
            xlsxObjPath: ['cacamba.numeroIdentificacao', 'veiculo.numeroIdentificacao'],
            calculateFilterExpression: (transportador, operation) => {
              return [['$cacamba.id$', '=', transportador], 'or', ['$veiculo.id$', '=', transportador]]
            },
            calculateSortValue: 'idCacamba',
            caption: 'Nº de Identificação da Caçamba',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              dataSource: {
                store: new CustomStore({
                  load: loadOptions => this.$utils.wrapRequestForGrid(getCacambasSolicitacao(
                    { loadOptions },
                    {},
                    false,
                    false
                  )),
                  byKey: key => key || null
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'numeroIdentificacao',
              valueExpr: 'id'
            }
          },
          {
            dataField: '$residuoPredominante.nome$',
            calculateDisplayValue: 'residuoPredominante.nome',
            calculateSortValue: 'idResiduoPredominante',
            caption: 'Classe de material/tipo de resíduo predominante',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              dataSource: new CustomStore({
                load: loadOptions =>
                  this.$utils.wrapRequestForGrid(getResiduosPredominantesSolicitacao(
                    { loadOptions },
                    { solicitacoesTransporte: ['situacao'] },
                    true,
                    false
                  )),
                byKey: key => key || null
              }),
              displayExpr: 'nome',
              valueExpr: 'nome'
            }
          },
          {
            dataField: '$veiculo.id$',
            calculateDisplayValue: 'veiculo.placa',
            calculateSortValue: 'idVeiculo',
            caption: 'Veículo (Placa)',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              dataSource: {
                store: new CustomStore({
                  load: loadOptions => this.$utils.wrapRequestForGrid(getVeiculosSolicitacao(
                    { loadOptions },
                    { solicitacoesTransporte: ['situacao'] },
                    true,
                    false
                  )),
                  byKey: key => key || null
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'placa',
              valueExpr: 'placa'
            }
          },
          {
            dataField: '$transportador->pessoa.nome_razao_social$',
            calculateDisplayValue: 'transportador.pessoa.nomeRazaoSocial',
            calculateSortValue: 'idTransportador',
            caption: 'Transportador',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              dataSource: {
                store: new CustomStore({
                  load: loadOptions =>
                    this.$utils.wrapRequestForGrid(getTransportadoresSolicitacoesReceber(
                      {
                        loadOptions,
                        fields: ['id', 'nomeRazaoSocial']
                      },
                      {
                        solicitacoesTransporte: ['id'],
                        destinoFinal: ['id'],
                        perfil: ['id']
                      },
                      false,
                      false,
                      false
                    )),
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'nomeRazaoSocial', asc: true }
                ],
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'nomeRazaoSocial'
            }
          },
          {
            dataField: '$transportador->pessoa.cpf_cnpj$',
            calculateDisplayValue: 'transportador.pessoa.cpfCnpj',
            calculateSortValue: 'idTransportador',
            caption: 'CPF/CNPJ',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              dataSource: {
                store: new CustomStore({
                  load: loadOptions =>
                    this.$utils.wrapRequestForGrid(getTransportadoresSolicitacoesReceber({
                      loadOptions,
                      fields: ['id', 'cpfCnpj']
                    },
                    {
                      solicitacoesTransporte: ['id'],
                      destinoFinal: ['id'],
                      perfil: ['id']
                    },
                    false,
                    false,
                    false)),
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'cpfCnpj', asc: true }
                ],
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'cpfCnpj',
              valueExpr: 'cpfCnpj'
            }
          },
          {
            dataField: 'dataHoraSolicitacao',
            caption: 'Data/Hora da Solicitação',
            format: 'dd/MM/yyyy - HH:mm',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            dataField: 'situacao',
            caption: 'Situação',
            // filterValue: 'Retirada',
            cssClass: 'situacaoFilterWrapper'
            // lookup: {
            //   allowClearing: true,
            //   dataSource: this.$utils.getDistinctSituacoes()
            // }
          },
          {
            type: 'buttons',
            width: 325,
            showInColumnChooser: false,
            buttons: [
              {
                cssClass: 'dx-button dx-button-primary mdi mdi-file-document',
                text: 'CTR',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('view', data)
                }
              },
              ...(hasPermission('Analisar Recebimento de Resíduos', 'altera') ? [{
                cssClass: 'dx-button dx-button-primary mdi mdi-file-document',
                text: 'AVALIAR RECEBIMENTO',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('evaluate', data)
                },
                visible ({ row: { data } }) {
                  return data.situacao === 'Retirada' || data.situacao === 'Em Pesagem' || data.situacao === 'Cancelamento Recusado'
                }
              }] : [])
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: loadOptions => {
            if (this.customFiltersGrid.situacao.or.length) {
              if (loadOptions.filter) {
                loadOptions.filter = [loadOptions.filter, 'and', [
                  'situacao',
                  'or',
                  this.customFiltersGrid.situacao.or
                ]]
              } else {
                loadOptions.filter = [
                  'situacao',
                  'or',
                  this.customFiltersGrid.situacao.or
                ]
              }
            }
            return this.$utils.wrapRequestForGrid(getSolicitacoesAvaliarRecebimento(loadOptions))
          }
        })
      },
      popupAvaliacaoVisible: false,
      solicitacaoPopup: null
    }
  },
  methods: {
    onMarkSituacaoFilter (data) {
      if (data.callback.previousValue !== data.callback.value) {
        data.callback.value
          ? this.customFiltersGrid.situacao.or.push({ eq: data.situacao })
          : this.customFiltersGrid.situacao.or = this.customFiltersGrid.situacao.or.filter(filter => filter.eq !== data.situacao)
        this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
      }
    },
    onGridContentReady () {
      const situacaoFilterWrapper = document.querySelector('.situacaoFilterWrapper.dx-editor-cell')
      const dropdown = document.querySelector('#dropdown-situacao')
      dropdown.style.display = 'unset'
      while (situacaoFilterWrapper.firstChild) {
        situacaoFilterWrapper.removeChild(situacaoFilterWrapper.firstChild)
      }
      situacaoFilterWrapper.appendChild(dropdown)
    },
    /**
     * Metodo de callback de quando e clicado no botao de acao da solicitacao,
     * com o objetivo de abrir a popup de acao da solicitacao.
     * @param {string} action - A acao que sera feita na solicitacao, 'view'
     * para visualizar a solicitacao, 'evaluate' para avaliar a solicitacao de
     * recebimento.
     * @param {Object} solicitacao - A solicitacao que foi clicada.
     */
    onSoliticaoActionClick (action, solicitacao) {
      const map = {
        view: () => {
          window.open(`${config.baseUrl}/FichaCTR/${solicitacao.id}`, '_blank')
        },
        evaluate: () => {
          this.solicitacaoPopup = solicitacao
          this.popupAvaliacaoVisible = true
        }
      }
      if (map[action]) {
        map[action]()
      }
    },

    /**
     * Metodo de callback de quando um popup altera da solicitacao.
     * Com o objetivo de recarregar a lista de solicitacoes.
     */
    onPopupUpdatedSolicitacao () {
      this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
    }
  }
}
</script>

<style lang="scss">
</style>
