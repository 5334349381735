import axios from 'axios'
import config from 'config'
import store from '@/store/login'
import lodash from 'lodash'
import { staticMethodsUtil } from '../utils'
import notify from 'devextreme/ui/notify'
import { mapActions } from 'vuex'

export const http = axios.create({
  baseURL: config.baseUrl
})

Object.defineProperty(http, 'CancelToken', {
  get () {
    return axios.CancelToken
  }
})

http.isCancel = function (token) {
  return axios.isCancel(token)
}

http.interceptors.request.use(config => {
  if (config.baseURL.match(config.baseUrl) && store.state.token) {
    config.headers['auth-token'] = store.state.token
  }
  if (config.data?.format === 'xlsx') {
    config.responseType = 'blob'
    config.isValid = true
  }
  return config
})

http.normalizeUrl = (path) => {
  return `${config.baseUrl.replace(/\/\/?$/, '')}/${path.replace(/^\/\/?/, '')}`
}

http.getUrl = (path) => {
  const url = http.normalizeUrl(path)
  const separator = url.match(/\?./) ? '&' : '?'
  return `${url}${separator}auth-token=${store.state.token}`
}

export default {
  install (Vue) {
    Vue.mixin({
      beforeCreate: function () {
        this.$http = http
      },
      methods: {
        ...mapActions('Login', ['logoutAction'])
      },
      async created () {
        http.interceptors.response.use(response => {
          if (response.data instanceof Blob) {
            if (response.config.isValid) {
              const blob = response.data
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              const name = window.location.href.split('/').slice(-1) || 'tabela'
              link.download = `${name}.xlsx`
              link.click()
              response.config.isValid = false
            }
          }
          return response
        }, async (error) => {
          const {
            response: {
              data: response,
              status
            }
          } = error
          const isRecovery = this.$route.path.includes('recovery') || this.$route.path.includes('destino-rapido') || this.$route.path.includes('transportadores') || this.$route.path.includes('confirmed')
          if (isRecovery) {
            return
          }
          if (['EXPIRED_TOKEN', 'INVALID_TOKEN'].includes(response?.details?.type)) {
            await this.logoutAction()
            await this.$router.push('/')
            notify('Sessão expirada. Faça login novamente', 'error', 7000)
          } else if ([500, 400].includes(status) && response.message) {
            notify(response.message, 'error', 7000)
          } else {
            // Contempla CustomError e demais códigos, caso não exista
            // mensagem no retorno, a função notifyError exibe
            // uma mensagem padrão
            staticMethodsUtil.notifyError(error)
          }
        })
      }
    })
  }
}

/**
 * Metodo de comando, com o objetivo de realizar uma requisicao.
 * @param {Object} param - Os dados da requisicao a ser feita.
 * @param {string} param.method - O metodo da requisicao.
 * @param {string} param.url - A URL da requisicao.
 * @param {Object} param.params - Os parametros da requisicao, em caso do metodo
 * 'get' esse e parametro 'options' e os parametros da query deve estar dentro
 * de uma prop chamada 'params'.
 * @param {Object} param.options - As opcoes adicionais da requisicao, como
 * 'headers', em caso do metodo 'get' nao utilize esse parametro, coloque as
 * opcoes no parametro 'params'.
 * @returns {Object} O resultado da requisicao. Com as props: 'success' se a
 * requisicao foi um sucesso, 'data' os dados da requisicao e 'error' se ocorreu
 * um erro.
 */
export async function doRequest ({ method, url, params, single = false, options = {} }) {
  try {
    const cleanObject = (parameters) => {
      const obj = parameters && typeof parameters === 'object'
        ? parameters instanceof Array ? [...parameters] : { ...parameters }
        : parameters
      if (obj && typeof obj === 'object') {
        for (const key in obj) {
          if (obj[key] && typeof obj[key] === 'object' && !(obj[key] instanceof Date)) {
            if (!Object.keys(obj[key]).length) {
              if (obj instanceof Array) {
                obj.splice(key, 1)
              } else {
                delete obj[key]
              }
            } else {
              obj[key] = cleanObject(obj[key])
            }
          }
        }
      }
      return obj
    }
    params = options.cleanObject === false ? params : cleanObject(params)
    const result = await http[method](`${config.baseUrl}/${url}`, params, options)

    if (single && lodash.has(result, 'data.data.rows[0]')) {
      return { success: true, data: result.data.data.rows[0], error: null }
    } else if (typeof result.data !== 'object') {
      return { success: true, data: result.data, error: null }
    }

    if ('success' in result.data && !result.data.success) {
      throw new Error(`Falha no retorno da requisicao de ${method} ${url}`)
    } else {
      return {
        success: true,
        data: 'data' in result.data ? result.data.data : result.data,
        error: null
      }
    }
  } catch (error) {
    const { response } = error
    console.error(response || error)
    // staticMethodsUtil.notifyError(response.data || error)
    throw response.data || error
  }
}
