<template>
  <div class="simple-grid">
    <div>
      <ODxPopup
        ref="dxPopupRef"
        :visible.sync="exportPopupVisible"
        :hide-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        max-height="200px"
        max-width="500px"
        title="Registros a exportar">
        <template #content>
          <div class="dx-field">
            <div class="dx-field-label">Quantidade de registros</div>
            <div class="dx-field-value">
              <DxNumberBox
                v-model="limitExport"
                :show-clear-button="true"
                :max="maxLimitExport"
                mode="number"
              />
            </div>
            <div class="dx-field-value">
              <br>
              <DxButton @click="onExport" text="Exportar"/>
            </div>
          </div>
        </template>
      </ODxPopup>
      <!--   Nota: esta propriedade foi retirada do grid pois estava gerando o seguinte bug:
       Ao modificar qualquer variável no data do componente, o grid faz reload-->
      <!--  :scrolling="{ useNative: true, showScrollbar: 'always' }"-->
      <DxDataGrid
        :allow-column-resizing="true"
        :column-auto-width="true"
        :editing="editingData"
        :column-min-width="20"
        :columns="gridColumns"
        :no-data-text="noDataText"
        :pager="{ visible: showPager, showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true }"
        :paging="{ pageSize, pageIndex: currentPage }"
        :remote-operations="true"
        :sync-lookup-filter-values="false"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="!!(!gridData.keyExpr)"
        @initialized="onGridReady"
        @content-ready="onGridContentReady"
        column-resizing-mode="widget"
        :search-enabled="false"
        :filter-value="filterValue"
        style="min-height: 150px"
        ref="gridInstance"
        v-bind="gridData">
        <DxToolbar>
          <DxItem
            location="after"
            template="customExportTemplate"
          />
          <DxItem
            name="columnChooserButton"
          />
        </DxToolbar>
        <dx-column-chooser mode="select" :enabled="true"/>
        <dx-selection :mode="selectionMode"/>
        <dx-filter-row :visible="true"/>
<!--        <dx-export :enabled="true" :allow-export-selected-data="true" :file-name="exportFileName"/>-->

        <dx-search-panel :visible="false" placeholder="Pesquisar..." />
        <template slot="colorCellTemplate" slot-scope="{ data }">
          <dx-color-box
            :read-only="true"
            :opened="false"
            :width="100"
            :value="data.data.cor"
            />
        </template>
        <template slot="textAreaTemplate">
          <dx-text-area></dx-text-area>
        </template>
        <dx-editing
          refresh-mode="full"
          :min-col-width="200"
          v-bind="editingData"
          :use-icons="false"
          :allow-updating="nativeForm && (editable || viewable)"
          :allow-adding="nativeForm && insertable"
          :allow-deleting="deletable"
          :form="{
            labelLocation: 'top',
            minColWidth: 200,
            colCount: 'auto',
            ...formOptions
          }"
          mode="popup">
          <dx-popup v-bind="popupData || {}" :show-title="true" :title="popupTitle">
            <dx-position my="center" at="center" of="window"/>
          </dx-popup>
        </dx-editing>
      <DxMasterDetail
      :enabled="showMasterDetail"
      template="masterDetailTemplate"
    />
    <template #masterDetailTemplate="{data}">
      <slot name="masterDetailSlot" :formData="data"/>
    </template>
    <template #customExportTemplate>
      <DxButton @click="onClickExport" icon="export" text="Planilha"/>
    </template>
      </DxDataGrid>
    </div>
    <div class="clearfix"></div>
  </div>
</template>
<script>
import DxColorBox from 'devextreme-vue/color-box'
import _ from 'lodash'
import {
  DxDataGrid,
  DxColumnChooser,
  DxSearchPanel,
  // DxExport,
  DxFilterRow,
  DxSelection,
  DxEditing,
  DxPosition,
  DxPopup,
  DxMasterDetail,
  DxItem,
  DxToolbar
} from 'devextreme-vue/data-grid'
import DxButton from 'devextreme-vue/button'
import { DxPopup as ODxPopup } from 'devextreme-vue/popup'
import { DxNumberBox } from 'devextreme-vue/number-box'
import { staticMethodsUtil } from '@/pluggables/utils'

export default {
  name: 'simple-grid',
  components: {
    DxDataGrid,
    DxColorBox,
    DxSearchPanel,
    // DxExport,
    DxFilterRow,
    DxColumnChooser,
    DxTextArea: () => import('devextreme-vue/text-area'),
    DxSelection,
    DxEditing,
    ODxPopup,
    DxMasterDetail,
    DxItem,
    DxToolbar,
    DxButton,
    DxNumberBox,
    DxPosition,
    DxPopup
  },
  props: {
    /**
     * Prop temporária para habilitar o formulário nativo do dxDataGrid
     */
    nativeForm: {
      type: Boolean,
      default: () => false
    },
    /**
     * Opções do formulário do devextreme
     */
    formOptions: {
      type: Object,
      default: () => { return {} }
    },
    entity: {
      type: String,
      required: true
    },
    getEditPath: {
      type: Function,
      default: null
    },
    selectionMode: {
      type: String,
      default: ''
    },
    popupTitle: {
      type: String,
      default: 'Editar dados'
    },
    exportFileName: {
      type: String,
      default: 'dados'
    },
    gridData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editingData: {
      type: Object,
      required: false,
      default: () => {
        return {
          allowUpdating: false,
          allowAdding: false,
          allowDeleting: true
        }
      }
    },
    popupData: {
      type: Object,
      default: () => {
        return {
          maxWidth: 600
        }
      }
    },
    fixedColumns: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    deletable: {
      type: Boolean,
      default: true
    },
    insertable: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    buttonsOnFixedArea: {
      type: Array,
      default: () => []
    },
    noDataText: {
      type: String,
      default: 'Nenhum registro encontrado.'
    },
    /**
     * Prop de se o grid tem a opcao de visualizar o item.
     */
    viewable: {
      type: Boolean,
      default: false
    },
    /**
     * Prop de se os botoes de acao (editar e deletar), irao ficar em uma nova
     * coluna, ou vao ser concatenados em uma coluna de botoes ja existente na
     * lista de colunas. Caso essa prop for false e nao tiver uma coluna de
     * botoes ja definida na lista de colunas será criada uma nova coluna de
     * botoes. Para criar uma coluna de botoes, basta criar uma coluna com a
     * prop 'type: "buttons"'.
     */
    addColumnToActionButtons: {
      type: Boolean,
      default: true
    },
    showPager: {
      type: Boolean,
      default: true
    },
    showMasterDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      exportPopupVisible: false,
      limitExport: 10,
      maxLimitExport: 0,
      currentPage: 0,
      pageSize: 10,
      gridColumns: [],
      customFilterValue: null,
      filterValue: null,
      instance: null
    }
  },

  watch: {
    /**
     * Metodo de callback de quando ocorre mudancas no valor da prop 'editable'.
     * Com o objetivo de atualizar a lista de colunas do Grid.
     */
    editable: '_setGridColumns',
    /**
     * Metodo de callback de quando ocorre mudancas no valor da prop 'deletable'.
     * Com o objetivo de atualizar a lista de colunas do Grid.
     */
    deletable: '_setGridColumns',
    /**
     * Metodo de callback de quando ocorre mudancas no valor da prop 'viewable'.
     * Com o objetivo de atualizar a lista de colunas do Grid.
     */
    viewable: '_setGridColumns'
  },
  /**
   * Metodo do ciclo de vida do vue. Com o objetivo de definir a lista de
   * colunas do grid.
   */
  async created () {
    this._setGridColumns()
  },
  methods: {
    async onExport () {
      const previousFilter = this.getDataGridInstance().getCombinedFilter() || []
      const sort = this.getDataGridInstance().state().columns.map(column => {
        if (column.sortOrder) {
          const customSort = this.gridData.columns.find(gridCol => gridCol.dataField === column.dataField && typeof gridCol.calculateSortValue === 'string')
          return {
            selector: customSort?.calculateSortValue || staticMethodsUtil.snakeToCamel(column.dataField.replaceAll('$', '')),
            desc: column.sortOrder === 'desc'
          }
        }
      }).filter(value => value)
      if (!(sort.some(s => s.selector === 'id'))) { sort.push({ selector: 'id', desc: false }) }
      const formattedColumns = this.$refs.gridInstance.instance.getVisibleColumns().map(i => {
        return {
          dataField: i.dataField,
          caption: i.caption,
          calculateDisplayValue: i.calculateSortValue ? i.calculateSortValue : undefined
        }
      }).filter(column => column && column.dataField && column.caption)
      const loadOptions = { sort, filter: [...previousFilter], take: this.limitExport, customQueryParams: { format: 'xlsx', columnsConfig: formattedColumns } }
      // Faço isso porque em algumas situações o CustomStore está dentro de dataSource.store
      if (this.gridData.dataSource.store) {
        await this.gridData.dataSource.store.load(loadOptions)
      } else {
        await this.gridData.dataSource.load(loadOptions)
      }
      this.exportPopupVisible = false
    },
    onClickExport () {
      this.exportPopupVisible = true
    },
    refresh () {
      return this.getDataGridInstance().refresh()
    },
    /**
    * Método setFilterValue que seta um valor de filtro custom para o grid
    */
    setFilterValue (data) {
      this.customFilterValue = data
    },

    /**
    * Método onGridReady que é disparado com a instancia de DxDataGrid do simpleGrid é incializada
    * emite o evento "gridReady" para o simpleGrid
    */
    onGridReady (data) {
      this.$emit('gridReady', data.component)
    },
    onGridContentReady (data) {
      this.$emit('content-ready', data.component)
      this.limitExport = this.getDataGridInstance().totalCount()
      this.maxLimitExport = this.getDataGridInstance().totalCount()
    },
    /**
     * Metodo responsável por devolver a instancia do dxDataGrid.
     * @returns {object} a instancia do dxDataGrid
     */
    getDataGridInstance () {
      return this.$refs.gridInstance.instance
    },

    /**
     * Metodo de comando, com o objetivo de definir a lista de colunas do grid.
     */
    _setGridColumns () {
      const buttons = []

      if (this.gridData.filterValue) {
        this.setFilterValue(this.gridData.filterValue)
      }

      if (!this.nativeForm && this.editable) {
        buttons.push({
          name: 'customEdit',
          template: (element, cellInfo) => {
            element.innerHTML = `
              <a class="dx-button dx-button-success dx-button-mode-contained dx-widget dx-button-has-text" href="${this.getEditPath ? this.getEditPath(cellInfo) : `/#/${_.kebabCase(this.entity)}/edit/${cellInfo.data.id}`}">
                <div class="dx-button-content"><span class="dx-link-edit dx-icon-edit dx-link-icon"></span> Editar</div>
              </a>
              `
            return element
          }
        })
      } else if (this.editable) {
        buttons.push({
          name: 'edit',
          text: 'Editar',
          cssClass: 'dx-button dx-button-success dx-button-mode-contained dx-widget dx-link-icon mdi mdi-pencil'
        })
      }

      if (this.deletable) {
        buttons.push({
          name: 'delete',
          cssClass: 'dx-button dx-button-danger dx-button-mode-contained dx-widget dx-link-icon mdi mdi-delete',
          text: 'Excluir'
        })
      }

      if (this.viewable) {
        buttons.push({
          text: 'Visualizar',
          cssClass: 'dx-button dx-button-success dx-button-mode-contained dx-widget dx-link-icon mdi mdi-magnify',
          ...(!this.nativeForm ? {
            onClick: data => {
              this.$router.push({
                path: this.getEditPath
                  ? this.getEditPath(data.row)
                  : `/${_.kebabCase(this.entity)}/edit/${data.row.data.id}`
              })
            },
            name: 'view'
          } : { name: 'edit' })
        })
      }

      buttons.push(...this.buttonsOnFixedArea)

      const buttonsCol = this.gridData.columns
        .find(col => col.type === 'buttons')

      if (this.addColumnToActionButtons || !buttonsCol) {
        this.gridColumns = [
          ...this.gridData.columns,
          ...(buttons.length
            ? [{
              type: 'buttons',
              // width: (buttons.length * 100) + 30,
              showInColumnChooser: false,
              buttons
            }]
            : [])
        ]
      } else {
        if (buttons) {
          buttonsCol.buttons = (buttonsCol.buttons || [])
            .concat(buttons)
        }
        this.gridColumns = [...this.gridData.columns]
      }
    }
  }
}
</script>

<style lang="scss">
$scrollbar-color: #aeaeae;

.dx-datagrid .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) > td:not(.dx-focused) {
  background-color: #adadad
}
.simple-grid {
  max-width: 100%;

  .dx-command-edit {
    background: #fff !important;
  }

  .dx-scrollable-container {
    scrollbar-color:  $scrollbar-color #E2E4E9;
    scrollbar-width: 18px;

    &::-webkit-scrollbar-track {
      background-color: #E2E4E9;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-color;
    }
  }

  .grid-paging-wrapper {
    padding: 5px 10px;
    font-size: 13px;

    label {
      font-size: 13px;
    }

    .middle-block {
      text-align: center;
    }

    .end-block {
      text-align: right;
    }
  }

  [role="gridcell"] {
    .dx-button-success, .dx-button-danger {
      color: #fff !important;
    }
    .dx-button {
      width: auto !important;
      height: 20px !important;
      padding: 1px 20px !important;
      line-height: 16px;

      .dx-button-content {
        line-height: 16px !important;
        padding: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      &:before {
        padding-right: 3px;
      }
    }
  }
}

#app {
  // .dx-button .dx-button-content {
  //   line-height: 14px;
  // }
  // .dx-button-has-text .dx-button-content {
  //   padding: 4px 18px 2px;
  // }
  .dx-pager {
    .dx-page-sizes {
      float: right;
      position: relative;

      &:before {
        content: "Registros por página: ";
        opacity: .6;
      }
    }
    .dx-pages {
      float: left;

      .dx-info {
        margin-left: 15px;
        opacity: .6;
        float: right;
        margin-top: 6px;
      }
    }
  }
}
</style>
