<template>
  <div>
    <FormPessoaUpdate
      :id="id"
      @save="onSave"
    />
  </div>
</template>
<script>
import FormPessoaUpdate from '@/pages/Cruds/Pessoa/Form/FormPessoaUpdate.vue'
export default {
  props: {
    id: {
      type: [Number, String]
    }
  },
  watch: {
    showModal (showModal) {
      this.$emit('update:modal', showModal)
    }
  },
  components: { FormPessoaUpdate },
  methods: {
    async onSave (payload = {}) {
      try {
        let message = null
        let result = null
        result = (await this.$http.put(this.$http.normalizeUrl(`/Pessoa/${this.id}`), payload)).data
        message = 'Edições feitas com sucesso'
        this.$utils.notifySuccess(message)
        this.$emit('save-sucess', result)
        if (!this.email) this.$router.push('/pessoa')
      } catch (error) {
        this.$utils.notifyError(error)
      }
    }
  }
}
</script>
